/* eslint-disable @typescript-eslint/no-explicit-any */
import apiClient from './apiClient';
import getContactsAPIClient from './GetContactAPIClient';

const loginAPI = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	login(token: string): Promise<any> {
		return apiClient.get('/user/login', {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + token,
			},
		});
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	logout(): Promise<any> {
		return apiClient.post('/user/logout');
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getAccounts(token: string): Promise<any> {
		return apiClient.post(
			'/getaccounts',
			{},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + token,
				},
			}
		);
	},

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getDealerCode(token: string, slxId: string, sfId: string): Promise<any> {
		return apiClient.post(
			'/account/code',
			{
				Account_ID_SLX__c: slxId,
				Id: sfId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + token,
				},
			}
		);
	},

	getOnboardedContacts(token: string, accountId: string): Promise<any> {
		return getContactsAPIClient.post(
			'/get-onboard-contacts',
			{
				apihubtoken: token,
				dealerid: accountId,
			},
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + token,
				},
			}
		);
	},

	serverWakeup(token: string): Promise<any> {
		return apiClient.get('/wakeup', {
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + token,
			},
		});
	},

	onboardingWakeup(): Promise<any> {
		return getContactsAPIClient.get('/wakeup');
	},
};

export default loginAPI;
