/* eslint-disable no-undef */
import React, { createRef } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import Spinner from './Components/Spinner';
import HomePage from './Pages/Home';
import LoginPage from './Pages/Login';

import { useCookies } from 'react-cookie';

import '@fontsource/league-spartan/700.css';
import './App.css';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

const useStyles = makeStyles({
	root: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		fontFamily: 'roboto, "Open Sans", Helvetica, sans-serif',
		backgroundColor: '#e9e9e9',
		margin: '0 auto',
		height: '100%',
	},
});

function App(): JSX.Element {
	const spinnerRef = createRef();
	const classes = useStyles();
	const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

	const [cookies, setCookie] = useCookies(['LoggedIn']);

	const LoginURL: string | URL = process.env.REACT_APP_SP_LOGIN_URL as string;

	const checkUserLogin = (value: boolean) => {
		setIsUserLoggedIn(value);
	};

	return (
		<Router>
			<Paper className={classes.root}>
				<Spinner spinnerRef={spinnerRef} />
				{!isUserLoggedIn && !cookies.LoggedIn && (
					<Redirect exact to='/login' />
				)}
				<Switch>
					<Route
						exact
						path='/'
						render={(props) => (
							<HomePage
								classes={undefined}
								theme={undefined}
								isUserLoggedIn={isUserLoggedIn}
								handleUserLoggedIn={checkUserLogin}
								{...props}
							/>
						)}
					/>
					<Route
						exact
						path='/login'
						component={() => {
							setCookie('LoggedIn', true, { path: '/' });
							window.location.replace(LoginURL);
							return null;
						}}
					/>
					<Route
						exact
						path='/logout'
						render={(props) => (
							<LoginPage
								checkUserLogin={checkUserLogin}
								{...props}
							/>
						)}
					/>
				</Switch>
			</Paper>
		</Router>
	);
}

export default App;
