/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
/* eslint-disable quotes */
import React, { forwardRef } from 'react';

import MaterialTable, { Icons } from 'material-table';

import moment from 'moment';

// import { makeStyles, withStyles } from '@material-ui/core/styles';

// import spartanBold from '../Assets/spartanBold.js';
// import spartanRegular from '../Assets/spartanRegular.js';
// import openSans from '../Assets/openSans.js';

import AddBox from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const tableIcons: Icons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => (
		<KeyboardArrowDown style={{ color: '#ffffff' }} {...props} ref={ref} />
	)),
	ThirdStateCheck: forwardRef((props, ref) => (
		<Remove {...props} ref={ref} />
	)),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

interface user {
	active: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	address: any;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	is_app_installed: boolean;
	language: string;
	locale: string;
	name: string;
	nickname: string;
	organization_id: string;
	photos: {
		picture: string;
		thumbnail: string;
	};
	picture: string;
	preferred_username: string;
	profile: string;
	sub: string;
	updated_at: string;
	urls: {
		custom_domain: string;
		enterprise: string;
		feed_elements: string;
		feed_items: string;
		feeds: string;
		groups: string;
		metadata: string;
		partner: string;
		profile: string;
		query: string;
		recent: string;
		rest: string;
		search: string;
		sobjects: string;
		tooling_rest: string;
		tooling_soap: string;
		users: string;
	};
	user_id: string;
	user_type: string;
	utcOffset: number;
	zoneinfo: string;
}

interface Account {
	Account_ID_SLX__c: string;
	BillingCountry: string;
	BillingState: string;
	BillingStateCode: string;
	BillingStreet: string;
	Id: string;
	Name: string;
	OwnerId: string;
	Status__c: string;
}

interface IUserTableProps {
	users: user[];
	account: Account;
}

function UserTable(props: IUserTableProps): JSX.Element {
	const formatPhone = (phone: any) => {
		const cleaned = ('' + phone).replace(/\D/g, '');
		const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
		return null;
	};

	return (
		<MaterialTable
			{...props}
			data={props.users}
			title={`${props.account.Name}` + ' - Onboarded Contacts'}
			columns={[
				{
					title: 'Date',
					field: 'createdDate',
					align: 'center',
					type: 'datetime',
					defaultSort: 'desc',
					sorting: true,
					render: (rowData: any) =>
						moment(rowData.createdDate).format(
							'MM/DD/YYYY hh:mm A'
						),
				},
				{ title: 'Name', field: 'name', align: 'left' },
				{ title: 'Title', field: 'title', align: 'left' },
				{ title: 'Department', field: 'department', align: 'left' },
				{ title: 'Email', field: 'email', align: 'left' },
				{
					title: 'Phone',
					field: 'mobile',
					align: 'center',
					render: (rowData: any) => formatPhone(rowData.mobile),
				},
			]}
			icons={tableIcons}
			style={{
				fontFamily: 'roboto, "Open Sans", Helvetica, sans-serif',
				boxShadow: 'none',
				width: '100%',
			}}
			options={{
				draggable: false,
				actionsColumnIndex: -1,
				exportButton: true,
				search: true,
				paging: false,
				headerStyle: {
					backgroundColor: '#000352',
					color: '#ffffff',
					borderRight: '2px solid #dadad9',
					textAlign: 'center',
					fontSize: '15px',
					fontWeight: 500,
					textTransform: 'uppercase',
					fontFamily: `'League Spartan', sans-serif`,
					maxHeight: '40px',
				},
				padding: 'dense',
				rowStyle: (rowData, index) => {
					if (index % 2) {
						return {
							backgroundColor: '#f3f3f3',
							padding: '0px 16px',
							height: 40,
						};
					} else {
						return { padding: '0px 16px', height: 40 };
					}
				},
			}}
		/>
	);
}

export default UserTable;
