/* eslint-disable no-undef */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import { useCookies } from 'react-cookie';

import { Button, Card, CardContent, Divider } from '@material-ui/core';

import '../App.css';
import Logo from '../Assets/White Logo.png';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	headerLogo: {
		height: '110px',
		width: '352px',
		marginLeft: '7px',
		[theme.breakpoints.down('lg')]: {
			height: '75px',
			width: '250px',
		},
	},
	card: {
		height: '400px',
		width: '400px',
		margin: '100px auto',
	},
	cardContent: {
		height: '200px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	darkBlue: {
		backgroundColor: '#000352',
	},
	marginBottom20: {
		marginBottom: '20px',
	},
}));

interface Props {
	checkUserLogin: (value: boolean) => void;
}

function LoginPage(props: Props): JSX.Element {
	const classes = useStyles();

	const history = useHistory();

	const [cookies] = useCookies(['SFToken']);

	useEffect(() => {
		if (cookies.SFToken) {
			props.checkUserLogin(true);
			history.push('/');
		}
	}, [props, history, cookies.SFToken]);

	return (
		<div className={classes.root}>
			<Card className={classes.card}>
				<div className={classes.darkBlue}>
					<img
						className={classes.headerLogo}
						alt='Applied Concepts Logo'
						src={Logo}
					/>
				</div>
				<Divider />
				<CardContent>
					<div className={classes.cardContent}>
						<span className={classes.marginBottom20}>
							You are now logged out. You may close the browser or
							click the link to log back into the sales portal
						</span>
						<a
							href={process.env.REACT_APP_SP_LOGIN_URL}
							rel='noopener noreferrer'>
							<Button>Log In</Button>
						</a>
					</div>
				</CardContent>
			</Card>
		</div>
	);
}

LoginPage.propTypes = {
	checkUserLogin: PropTypes.func.isRequired,
};

export default LoginPage;
