import axios from 'axios';

const getContactsAPIClient = axios.create({
	baseURL: process.env.REACT_APP_SP_GET_CONTACTS_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

export default getContactsAPIClient;
