import React, { useEffect } from 'react';

import NavigationDrawer from '../Components/NavigationDrawer';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom';
import { History } from 'history';

import loginAPI from '../API/LoginAPI';

import { trackPromise } from 'react-promise-tracker';

import { useCookies } from 'react-cookie';

const useStyles = makeStyles({
	root: {
		backgroundColor: '#ffffff',
		height: '100vh',
	},
});

interface homePageProps {
	isUserLoggedIn: boolean;
	handleUserLoggedIn: (value: boolean) => void;
	history: History;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	classes: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	theme: any;
}

interface user {
	active: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	address: any;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	is_app_installed: boolean;
	language: string;
	locale: string;
	name: string;
	nickname: string;
	organization_id: string;
	photos: {
		picture: string;
		thumbnail: string;
	};
	picture: string;
	preferred_username: string;
	profile: string;
	sub: string;
	updated_at: string;
	urls: {
		custom_domain: string;
		enterprise: string;
		feed_elements: string;
		feed_items: string;
		feeds: string;
		groups: string;
		metadata: string;
		partner: string;
		profile: string;
		query: string;
		recent: string;
		rest: string;
		search: string;
		sobjects: string;
		tooling_rest: string;
		tooling_soap: string;
		users: string;
	};
	user_id: string;
	user_type: string;
	utcOffset: number;
	zoneinfo: string;
}

function HomePage(props: homePageProps): JSX.Element {
	const [user, setUser] = React.useState({} as user);
	const classes = useStyles();
	const history = useHistory();
	const [cookies, setCookie, removeCookie] = useCookies([
		'SFToken',
		'User',
		'LoggedIn',
	]);

	const token = props.history.location.search.substring(7);

	useEffect(() => {
		async function login() {
			try {
				const response = await loginAPI.login(token);
				setUser(response.data);
				setCookie('SFToken', token, { path: '/' });
				setCookie('User', response.data, { path: '/' });
				setCookie('LoggedIn', true, { path: '/' });
			} catch (error) {
				console.error(error);
				removeCookie('SFToken');
				removeCookie('User');
				removeCookie('LoggedIn');
				history.push('/logout');
			}
		}
		const wakeup = async () => {
			try {
				const response = await trackPromise(
					loginAPI.serverWakeup(token)
				);
				console.log(response);
			} catch (err) {
				console.log(err);
			}
		};

		const onboardedContactsWakeup = async () => {
			try {
				const response = await trackPromise(
					loginAPI.onboardingWakeup()
				);
				console.log(response);
			} catch (err) {
				console.log(err);
			}
		};

		if (
			Object.keys(user).length === 0 ||
			(!cookies.SFToken && !cookies.User)
		) {
			wakeup();
			onboardedContactsWakeup();
			login();
		}
	});

	return (
		<div className={classes.root}>
			<NavigationDrawer user={user} {...props} />
		</div>
	);
}

HomePage.propTypes = {
	history: PropTypes.object.isRequired,
};

export default HomePage;
