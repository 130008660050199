/* eslint-disable no-undef */
import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import moment from 'moment';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import {
	Card,
	CardContent,
	Dialog,
	DialogContent,
	Divider,
	Drawer,
	AppBar,
	CssBaseline,
	IconButton,
	Toolbar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Button,
	TextField,
	CardHeader,
	Tooltip,
} from '@material-ui/core';

import { ExitToApp, FileCopy, Search, Widgets } from '@material-ui/icons';

import { History } from 'history';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Autocomplete, {
	AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';

import { useCookies } from 'react-cookie';

import { trackPromise } from 'react-promise-tracker';

import Logo from '../Assets/Dark Blue Logo.png';
import SFLogo from '../Assets/SF Logo.png';
import Gearhead from '../Assets/Dark Blue Gearhead.png';
import TestCallsIcon from '../Assets/Test Calls.png';
import EnrollmentIcon from '../Assets/Enrollment.png';
import TrainingReports from '../Assets/training-reports-applied-concepts-reporting-center.png';
import ReportScheduler from '../Assets/report-scheduler-applied-concepts-reporting-center.png';
// import ActivityTracker from '../Assets/lms-activity-tracker-applied-concepts-reporting-center.png';
import EnrollmentOverview from '../Assets/enrollment-status-applied-concepts-reporting-center.png';

import '../App.css';
import loginAPI from '../API/LoginAPI';
import UserTable from './UserTable';

const drawerWidth = 249;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			backgroundColor: '#ffffff',
			boxShadow:
				'0px 0px 4px -1px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 7px 0px rgb(0 0 0 / 12%)',
			'& div': {
				minHeight: '100px',
			},
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			'@media (max-width: 1600px)': {
				width: '200px',
			},
		},
		drawerPaper: {
			width: drawerWidth,
			backgroundColor: '#000352',
			color: '#ffffff',
			'@media (max-width: 1600px)': {
				width: '200px',
			},
		},
		drawerContainer: {
			overflow: 'auto',
			paddingTop: '95px',
			'@media (max-width: 1600px)': {
				paddingTop: '55px',
			},
		},
		list: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginTop: '20px',
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
			backgroundColor: '#efefef',
			marginTop: '100px',
			height: 'calc(100vh - 100px)',
		},
		headerLogo: {
			height: '75px',
			width: '250px',
			marginLeft: '7px',
		},
		headerLogoutButton: {
			color: '#ffffff',
			fontSize: '20px',
			fontWeight: 'bold',
			position: 'relative',
			top: '325px',
			fontFamily: '"League Spartan", sans-serif',
			letterSpacing: 'normal',
			'@media (max-width: 1600px)': {
				top: '280px',
				fontSize: '16px',
			},
		},
		headerLogoutIcon: {
			marginTop: '10px',
			marginRight: '10px',
		},
		icon: {
			fontSize: '2rem',
			'@media (max-width: 1600px)': {
				fontSize: '1.5rem',
			},
		},
		listItem: {
			'& span': {
				letterSpacing: 'normal',
				fontSize: '14px',
				textTransform: 'uppercase',
			},
			'@media (max-width: 1600px)': {
				'& span': {
					fontSize: '12px',
				},
			},
		},
		drawerAvatar: {
			width: '105px',
			height: '105px',
			borderRadius: '50%',
			margin: '0 auto',
			display: 'flex',
			justifyContent: 'center',
			'@media (max-width: 1600px)': {
				width: '75px',
				height: '75px',
				fontSize: '24px',
			},
		},
		salesForce: {
			height: '130px',
			width: '130px',
			padding: '10px',
			border: '1px solid #dadad9',
			boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
			borderRadius: '2px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			'@media (max-width: 1600px)': {
				height: '100px',
				width: '100px',
			},
		},
		appTitle: {
			color: '#000352',
			fontWeight: 'bolder',
			fontSize: '20px',
			letterSpacing: 'normal',
			fontFamily: '"League Spartan", sans-serif',
			textTransform: 'none',
			textDecoration: 'none',
		},
		tabTitle: {
			fontWeight: 'bold',
			fontSize: '26px',
			letterSpacing: 'normal',
			fontFamily: '"League Spartan", sans-serif',
			textTransform: 'uppercase',
		},
		orangeCircle: {
			height: '80px',
			width: '80px',
			borderRadius: '50%',
			border: '3px solid #d65200',
			position: 'absolute',
			right: '378px',
			top: '172px',
			'@media (max-width: 1600px)': {
				top: '165px',
				right: '318px',
			},
		},
		whiteCircle: {
			height: '130px',
			width: '130px',
			borderRadius: '50%',
			border: '3px solid #ffffff',
			opacity: '0.3',
			position: 'absolute',
			top: '203px',
			right: '401px',
			'@media (max-width: 1600px)': {
				top: '200px',
				right: '339px',
			},
		},
		appCard: {
			height: '705px',
			width: '1540px',
			marginTop: '31px',
			'@media (max-width: 1600px)': {
				height: '550px',
				width: '1250px',
			},
		},
		cardHeader: {
			width: '100%',
			backgroundColor: '#000352',
			color: '#ffffff',
			height: '110px',
			boxShadow: '0px 7px 7px rgba(0, 0, 0, 0.1)',
			'@media (max-width: 1600px)': {
				height: '100px',
			},
		},
		cardHeaderContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingTop: '15px',
		},
		cardHeaderNameContainer: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'left',
			marginLeft: '49px',
			'@media (max-width: 1600px)': {
				marginLeft: '55px',
			},
		},
		cardHeaderUserName: {
			fontSize: '32px',
			fontWeight: 'bold',
			textTransform: 'uppercase',
			'@media (max-width: 1600px)': {
				fontSize: '28px',
			},
		},
		cardHeaderCTA: {
			display: 'flex',
			flexDirection: 'column',
			textAlign: 'left',
			marginRight: '80px',
			marginTop: '-6px',
			letterSpacing: 'normal',
			'@media (max-width: 1600px)': {
				marginRight: '55px',
			},
		},
		cardHeaderCTAText: {
			fontSize: '24px',
			fontWeight: 'bold',
			fontFamily: '"League Spartan", sans-serif',
			zIndex: 2,
			color: 'rgba(255, 255, 255, 0.8)',
			'@media (max-width: 1600px)': {
				fontSize: '18px',
			},
		},
		cardHeaderWelcomeText: {
			fontSize: '26px',
			marginTop: '-7px',
			fontFamily: '"League Spartan", sans-serif',
			color: 'rgba(255, 255, 255, 0.8)',
			'@media (max-width: 1600px)': {
				fontSize: '20px',
			},
		},
		cardHeaderDateText: {
			fontSize: '22px',
			zIndex: 2,
		},
		cardContent: {
			padding: '0px',
			marginTop: '37px',
		},
		appLinkContainer: {
			display: 'flex',
			marginTop: '40px',
		},
		appLinkTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '10px',
			textAlign: 'left',
			letterSpacing: 'normal',
		},
		testCallsLinkTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '15px',
			textAlign: 'left',
			letterSpacing: 'normal',
		},
		sfLinkTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '22px',
			textAlign: 'left',
			letterSpacing: 'normal',
		},
		salesForceLink: {
			marginLeft: '42px',
			textDecoration: 'none',
			color: '#ffffff',
		},
		reportingCenterLink: {
			marginLeft: '10px',
			textDecoration: 'none',
			color: '#ffffff',
		},
		testCallsLink: {
			marginLeft: '65px',
			textDecoration: 'none',
			'@media (max-width: 1600px)': {
				marginLeft: '66px',
			},
		},
		enrollmentLink: {
			marginLeft: '62px',
			textDecoration: 'none',
			'@media (max-width: 1600px)': {
				marginLeft: '64px',
			},
		},
		enrollmentLinkImg: {
			height: '57px',
		},
		acWebsiteLink: {
			marginLeft: '80px',
			textDecoration: 'none',
			'@media (max-width: 1600px)': {
				marginLeft: '40px',
			},
		},
		tabTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			marginLeft: '50px',
			textAlign: 'left',
			lineHeight: 'normal',
			'@media (max-width: 1600px)': {
				marginLeft: '50px',
			},
		},
		appLinkImg: {
			height: '75px',
			'@media (max-width: 1600px)': {
				height: '50px',
			},
		},
		reportingCenterLinkImg: {
			width: '75px',
		},
		flexCenter: {
			display: 'flex',
			justifyContent: 'center',
		},
		flexStart: {
			display: 'flex',
			justifyContent: 'flex-start',
		},
		logoutContainer: {
			marginTop: '450px',
			'@media (max-width: 1600px)': {
				marginTop: '330px',
			},
		},
		avatarImg: {
			width: '105px',
			height: '105px',
			border: '2px solid lightgrey',
			borderRadius: '50%',
			'@media (max-width: 1600px)': {
				width: '75px',
				height: '75px',
			},
		},
		listItemIcon: {
			color: '#ffffff',
			marginLeft: '26px',
			'@media (max-width: 1600px)': {
				marginLeft: '10px',
			},
		},
		navListItem: {
			paddingBottom: '5px',
			marginBottom: '10px',
			paddingTop: '3px',
		},
		appSubtitle: {
			textTransform: 'none',
			color: '#3b3b3b',
		},
		onboardingCard: {
			height: 400,
			width: 500,
			'@media (max-width: 1600px)': {
				height: 300,
			},
		},
	})
);

interface user {
	active: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	address: any;
	email: string;
	email_verified: boolean;
	family_name: string;
	given_name: string;
	is_app_installed: boolean;
	language: string;
	locale: string;
	name: string;
	nickname: string;
	organization_id: string;
	photos: {
		picture: string;
		thumbnail: string;
	};
	picture: string;
	preferred_username: string;
	profile: string;
	sub: string;
	updated_at: string;
	urls: {
		custom_domain: string;
		enterprise: string;
		feed_elements: string;
		feed_items: string;
		feeds: string;
		groups: string;
		metadata: string;
		partner: string;
		profile: string;
		query: string;
		recent: string;
		rest: string;
		search: string;
		sobjects: string;
		tooling_rest: string;
		tooling_soap: string;
		users: string;
	};
	user_id: string;
	user_type: string;
	utcOffset: number;
	zoneinfo: string;
}

interface IProps {
	user: user;
	history: History;
}

interface Account {
	Account_ID_SLX__c: string;
	BillingCountry: string;
	BillingState: string;
	BillingStateCode: string;
	BillingStreet: string;
	Id: string;
	Name: string;
	OwnerId: string;
	Status__c: string;
}

function NavigationDrawer(props: IProps): JSX.Element {
	const classes = useStyles();
	const [selectedDrawerButton, setSelectedDrawerButton] = React.useState(0);
	const [dealerCode, setDealerCode] = React.useState('');
	const [account, setAccount] = React.useState<Account>();
	const [accounts, setAccounts] = React.useState([]);
	const [inputValue, setInputValue] = React.useState('');
	const [users, setUsers] = React.useState([]);
	const [isUserModalOpen, setIsUserModalOpen] = React.useState(false);

	const [cookies, removeCookie] = useCookies(['SFToken', 'User', 'LoggedIn']);

	const token = props.history.location.search.substring(7);
	const LoginURL: string | URL = process.env.REACT_APP_SP_LOGIN_URL as string;

	const enrollmentLink = `${process.env.REACT_APP_ENROLLMENT_URL}?${token}`;
	const testCallsLink = `${process.env.REACT_APP_TEST_CALLS_URL}?${token}`;
	const trainingReportsLink = `${process.env.REACT_APP_TRAINING_REPORTS_URL}?${token}`;
	const reportSchedulerLink = `${process.env.REACT_APP_REPORT_SCHEDULER_URL}?token=${token}`;
	const activityTrackerLink = `${process.env.REACT_APP_TI_ACTIVITY_TRACKER_LINK}?${token}`;
	const accountStatusLink = `${process.env.REACT_APP_ACCOUNT_STATUS_LINK}?${token}`;
	const apiHubToken = `${process.env.REACT_APP_API_HUB_TOKEN}`;

	const handleGetDealerCode = (value: Account | null) => {
		console.log(value);
		accounts.forEach(async (account: Account) => {
			if (value !== null && account.Name === value.Name) {
				try {
					const response = await trackPromise(
						loginAPI.getDealerCode(
							token,
							account.Account_ID_SLX__c,
							account.Id
						)
					);
					setDealerCode(response.data);
					setAccount(value);
				} catch (error) {
					console.log(error);
				}
			}
		});
	};

	const handleCloseUserModal = () => {
		setIsUserModalOpen(false);
	};

	const handleOpenUserModal = async () => {
		setIsUserModalOpen(true);
		if (account) {
			try {
				const response = await trackPromise(
					loginAPI.getOnboardedContacts(apiHubToken, account.Id)
				);
				console.log(response);
				setUsers(response.data);
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleDrawerNavClick = async (value: number) => {
		setSelectedDrawerButton(value);
		if (value === 1) {
			try {
				const response = await trackPromise(
					loginAPI.getAccounts(token)
				);
				setAccounts(response.data.records);
				setDealerCode('');
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleLogout = () => {
		removeCookie('SFToken', { path: '/' });
		removeCookie('User', { path: '/' });
		removeCookie('LoggedIn', { path: '/' });
		window.location.replace(LoginURL);
	};

	useEffect(() => {
		if (!props.user) {
			handleLogout();
		}
	});

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position='fixed' className={classes.appBar}>
				<Toolbar>
					<img
						className={classes.headerLogo}
						alt='Applied Concepts Logo'
						src={Logo}
					/>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant='permanent'
				classes={{
					paper: classes.drawerPaper,
				}}>
				<Toolbar />
				<div className={classes.drawerContainer}>
					<div className={classes.drawerAvatar}>
						{props.user.photos && props.user.photos.picture && (
							<img
								className={classes.avatarImg}
								src={props.user.photos.picture}
								alt='SF avatar'
							/>
						)}
					</div>
					<List className={classes.list}>
						{['Dashboard', 'Dealer Codes'].map((text, index) => (
							<ListItem
								onClick={() => {
									handleDrawerNavClick(index);
								}}
								selected={index === selectedDrawerButton}
								button
								key={text}
								className={classes.navListItem}>
								<ListItemIcon className={classes.listItemIcon}>
									{text === 'Dashboard' && <Widgets />}
									{text === 'Dealer Codes' && <Search />}
								</ListItemIcon>
								<ListItemText
									className={classes.listItem}
									primary={text}
								/>
							</ListItem>
						))}
					</List>
				</div>
				<div className={classes.logoutContainer}>
					{(cookies.SFToken || token !== '') && (
						<List className={classes.list}>
							<ListItem
								className={classes.navListItem}
								onClick={() => handleLogout()}
								button>
								<ListItemIcon className={classes.listItemIcon}>
									<ExitToApp
										className={classes.headerLogoutIcon}
									/>
								</ListItemIcon>
								<ListItemText
									className={classes.listItem}
									primary='Logout'
								/>
							</ListItem>
						</List>
					)}
				</div>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.flexCenter}>
					<Card className={classes.appCard}>
						<div className={classes.cardHeader}>
							<div className={classes.cardHeaderContainer}>
								<span
									className={classes.cardHeaderNameContainer}>
									<span
										className={classes.cardHeaderUserName}>
										Hi {props.user.given_name}
									</span>
									<span
										className={
											classes.cardHeaderWelcomeText
										}>
										Welcome back!
									</span>
								</span>
								<span className={classes.cardHeaderCTA}>
									<span className={classes.cardHeaderCTAText}>
										Today is the most productive day of your
										week
									</span>
									<span
										className={classes.cardHeaderDateText}>
										{moment().format('MMMM D, YYYY')}
									</span>
								</span>
							</div>
							<div className={classes.orangeCircle}></div>
							<div className={classes.whiteCircle}></div>
						</div>
						<Divider />
						{selectedDrawerButton === 0 && (
							<CardContent className={classes.cardContent}>
								<div className={classes.flexStart}>
									<span className={classes.tabTitleContainer}>
										<span className={classes.tabTitle}>
											Applications
										</span>
										<span style={{ fontSize: '18px' }}>
											<span
												style={{
													fontWeight: 'bold',
													color: '#252832',
												}}>
												Find your
											</span>{' '}
											<span
												style={{
													fontWeight: 'bold',
													color: 'rgba(37, 40, 50, 0.5)',
												}}>
												resources here
											</span>
										</span>
									</span>
								</div>
								<div className={classes.appLinkContainer}>
									<a
										className={classes.salesForceLink}
										href={process.env.REACT_APP_SF_LINK_URL}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.appLinkImg
													}
													src={SFLogo}
													alt='Sales Force Logo'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													Salesforce
												</span>
												<span
													style={{
														color: '#3b3b3b',
													}}>
													CRM
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.testCallsLink}
										href={testCallsLink}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.appLinkImg
													}
													src={TestCallsIcon}
													alt='Icon with phone and message bubbles'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													Test Calls
												</span>
												<span
													className={
														classes.appSubtitle
													}>
													Call Resources
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.enrollmentLink}
										href={enrollmentLink}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.enrollmentLinkImg
													}
													src={EnrollmentIcon}
													alt='Person Icon'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													Enrollment
												</span>
												<span
													className={
														classes.appSubtitle
													}>
													Sign Up
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.acWebsiteLink}
										href={
											process.env
												.REACT_APP_AC_WEBSITE_LINK
										}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.appLinkImg
													}
													src={Gearhead}
													alt='Applied Concepts Gearhead'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													AC Website
												</span>
												<span
													className={
														classes.appSubtitle
													}>
													appliedconcepts.com
												</span>
											</span>
										</Button>
									</a>
								</div>
								<div className={classes.appLinkContainer}>
									<a
										className={classes.salesForceLink}
										href={accountStatusLink}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.reportingCenterLinkImg
													}
													src={EnrollmentOverview}
													alt='Reporting Center Logo'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													Enrollment Stats
												</span>
												<span
													className={
														classes.appSubtitle
													}>
													Accounts Overview
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.salesForceLink}
										href={trainingReportsLink}
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img
													className={
														classes.reportingCenterLinkImg
													}
													src={TrainingReports}
													alt='Reporting Center Logo'
												/>
											</div>
											<span
												className={
													classes.sfLinkTitleContainer
												}>
												<span
													className={
														classes.appTitle
													}>
													Training Reports
												</span>
												<span
													className={
														classes.appSubtitle
													}>
													Power Phone Ups
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.reportingCenterLink}
										href={reportSchedulerLink}
										target='_BLANK'
										rel='noopener noreferrer'>
										<Button>
											<div className={classes.salesForce}>
												<img className={classes.reportingCenterLinkImg} src={ReportScheduler} alt='Reporting Center Logo' />
											</div>
											<span className={classes.sfLinkTitleContainer}>
												<span className={classes.appTitle}>
													Report Scheduler
												</span>
												<span className={classes.appSubtitle}>
												Training Reports
												</span>
											</span>
										</Button>
									</a>
									<a
										className={classes.reportingCenterLink}
										href={activityTrackerLink}
										rel='noopener noreferrer'>
										{/* <Button>
									<div className={classes.salesForce}>
										<img className={classes.reportingCenterLinkImg} src={ActivityTracker} alt='Reporting Center Logo' />
									</div>
									<span className={classes.sfLinkTitleContainer}>
										<span className={classes.appTitle}>
											TI Activity Tracker
										</span>
										<span className={classes.appSubtitle}>
											TI Reports
										</span>
									</span>
								</Button> */}
										.
									</a>
									{/* <a className={classes.reportingCenterLink} href={accountStatusLink} rel='noopener noreferrer'>
								<Button>
									<div className={classes.salesForce}>
										<img className={classes.reportingCenterLinkImg} src={ActivityTracker} alt='Reporting Center Logo' />
									</div>
									<span className={classes.sfLinkTitleContainer}>
										<span className={classes.appTitle}>
											Account Status
										</span>
										<span className={classes.appSubtitle}>
											Enrollment Overview
										</span>
									</span>
								</Button>
							</a> */}
								</div>
							</CardContent>
						)}
						{selectedDrawerButton === 1 && (
							<CardContent>
								<Dialog
									maxWidth='lg'
									fullWidth
									open={isUserModalOpen}
									onClose={handleCloseUserModal}>
									<DialogContent>
										<UserTable
											{...props}
											users={users}
											account={account as Account}
										/>
									</DialogContent>
								</Dialog>
								<div className={classes.flexStart}>
									<span className={classes.tabTitleContainer}>
										<span className={classes.tabTitle}>
											Onboarding Dealer Codes
										</span>
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-evenly',
										marginTop: 50,
									}}>
									<Card
										className={classes.onboardingCard}
										variant='outlined'>
										<CardHeader
											style={{
												color: '#ffffff',
												backgroundColor: '#000352',
											}}
											title='Accounts'
										/>
										<Divider />
										<CardContent>
											<div
												style={{
													color: '#707070',
													fontFamily: 'Open Sans',
													fontWeight: 'bold',
													fontSize: '18px',
													marginTop: 10,
												}}>
												Select a dealer from the
												dropdown to view the dealer code
											</div>
											<div
												style={{ margin: '50px auto' }}>
												<Autocomplete
													options={accounts}
													inputValue={inputValue}
													onInputChange={(
														event,
														newInputValue
													) => {
														setInputValue(
															newInputValue
														);
													}}
													onChange={(
														event: React.ChangeEvent<unknown>,
														newValue: Account | null
													) => {
														handleGetDealerCode(
															newValue
														);
													}}
													getOptionLabel={(
														option: Account
													) => option.Name}
													renderInput={(
														params: AutocompleteRenderInputParams
													) => (
														<TextField
															{...params}
															variant='outlined'
														/>
													)}
												/>
											</div>
											{dealerCode !== '' && (
												<div>
													<Button
														onClick={() =>
															handleOpenUserModal()
														}>
														Current Onboarded Users
													</Button>
												</div>
											)}
										</CardContent>
									</Card>
									<Card
										className={classes.onboardingCard}
										variant='outlined'>
										<CardHeader
											style={{
												color: '#ffffff',
												backgroundColor: '#000352',
											}}
											title='Dealer Code'
										/>
										<Divider />
										<CardContent
											style={{
												display: 'flex',
												alignItems: 'center',
												height: '80%',
												fontSize: '48px',
												color: '#d65200',
												fontWeight: 'bold',
											}}>
											<div
												style={{
													margin: '0px auto',
													paddingLeft: 40,
												}}>
												{dealerCode}
												{dealerCode !== '' && (
													<div>
														<CopyToClipboard
															onCopy={(text) => {
																alert(
																	`Copied to Clipboard: ${text}`
																);
															}}
															text={`${process.env.REACT_APP_ONBOARDING_LINK}?${dealerCode}`}>
															<Tooltip title='Click to copy the link with the dealer code'>
																<IconButton>
																	<FileCopy />
																</IconButton>
															</Tooltip>
														</CopyToClipboard>
													</div>
												)}
											</div>
										</CardContent>
									</Card>
								</div>
							</CardContent>
						)}
					</Card>
				</div>
			</main>
		</div>
	);
}

NavigationDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

export default NavigationDrawer;
